<template>
  <PageContainer>
    <PageTitle>
      <div class="flex flex-row items-center gap-3">
        <div>
          {{ trans('PAGE_TITLE:NewContacts') }}
        </div>
        <VTooltip>
          <span class="flex flex-row items-center">
            <Button
                variant="link"
                @clicked="openBeforeYouStartSearchingModal(true)"
            >
              <base-icon  name="info" width="20" height="20">
              </base-icon>
            </Button>
          </span>
          <template #popper>
            {{ trans('LABEL:BeforeYouStartSearching') }}
          </template>
        </VTooltip>
      </div>
      <BadgeFrame v-if="userData && userData.isBanned">
        <Badge tagName="span" variant="badgeError">
          {{ trans('LABEL:AccountBlocked') }}
        </Badge>
      </BadgeFrame>
    </PageTitle>
    <PageContentWithSidebar>
      <!-- Filter sidebar START -->
      <Sidebar>
        <t-card class="w-full lg:w-sidebar lg:max-h-search-container  overflow-y-scroll">
          <!-- MOBILE SIDEBAR START-->
          <div class="block lg:hidden bg-white w-full flex flex-row justify-between">
            <Button
              @clicked="changeNav('favourites')"
              :iconActive="nav.favourites"
              variant="icon"
              icon="funnel"
            ></Button>
            <Button
              @clicked="changeNav('filters')"
              :iconActive="nav.filters"
              variant="icon"
              icon="filters"
            ></Button>
            <Button
              @clicked="changeNav('remembered')"
              :iconActive="nav.remembered"
              variant="icon"
              icon="starFilled"
            ></Button>
          </div>
          <!-- MOBILE SIDEBAR END-->
          <!-- DESKTOP SIDEBAR START-->
	        <div
            v-if="searchAvailableAt === null"
            class="flex flex-col gap-3"
          >
            <ListHeaderSection
              :text="trans('LABEL:Search')"
              no-counters
              class="lg:mb-1"
            >
              <base-icon :width="16" :height="16" name="pin">
              </base-icon>
            </ListHeaderSection>

            <Autocomplete
              :disabled="searchAvailableAt !== null"
              :placeholder="trans('PLACEHOLDER:StartTypingCityName')"
              type="location"
              @result-selected="acceptLocation"
              ref="autocomplete"
            />
		        <div class="flex w-full flex-row justify-between">
              <Button
                class="text-s"
                :disabled="this.userSearchLocation === userData.location.id"
                :text="trans('LABEL:SearchUseMyLocation')"
                variant="link"
                @clicked="useMyLocation(userData)"
              />
              <Button
                class="text-s"
                :text="trans('BUTTON:Map')"
                variant="link"
                @clicked="openMapModal(userData)"
              />
			        <Button
                :loading="profilesSearching"
                :disabled="this.userSearchLocation === null"
                :text="trans('BUTTON:Search')"
                @clicked="(profilesToShowCount > 0 && searchAvailableAt === null)
                  || userData.rememberedUsers.length > 0 ?
                  showStartNewSearchAlertModal(true) : searchProfiles()"
              />
		        </div>
	        </div>
          <div v-else class="flex flex-col gap-1 text-base">
            <div class="flex flex-row w-full justify-start">{{ trans('LABEL:SearchAvailableIn') }}:</div>
            <div class="flex flex-row w-full justify-center">
              <Countdown
                :end-date="searchAvailableAt"
                @ended="clearTimer"
                class="font-semibold text-redAccent"
              />
            </div>
          </div>
          <SidebarSectionSeparatorLine class=""/>
          <SearchSidebar
            @location-selected="locationSelected"
            :current-nav="nav"
            :highlight-first-location="profilesToShowCount > 0 || userData.rememberedUsers.length > 0"
          />
          <!-- DESKTOP SIDEBAR END -->
        </t-card>
      </Sidebar>
			<!--	Loading spinner    -->
      <div v-if="!isMobile || (isMobile && !nav.remembered)">
        <div v-if="usersLoading">
          <Loader class="w-full mt-3 lg:mt-0 lg:w-main-container lg:h-sidebar"
            :class="getHeightOfScreenWidth(1)"
          />
        </div>

        <!-- Search results START-->
        <div v-else class="flex flex-col lg:mb-6 w-full space-y-3 items-center">
          <t-card class="w-full flex flex-col lg:w-main-container lg:min-h-sidebar"
            v-if="usernameResult"
          >
            <div v-if="!usersLoading" class="w-full flex justify-center">
              <div class="justify-items-center leading-normal gap-3">
	              <template v-if="!usersLoading && !userData.isBanned">
		              <div class="w-full lg:w-auto">
			              <ProfileGuest
                      @profile-skipped="skipProfile()"
                      :profile-skipping="profileSkipping"
                      :getting-another-profile="gettingAnotherProfile"
                      @add-to-remembered="addToRemembered($event)"
                      :profiles-left-to-show="profilesToShowCountMessage"
                      :username="usernameResult"
                    />
		              </div>
	              </template>
              </div>
            </div>
          </t-card>
	        <template v-if="profileInfoLoaded && containsError">
		        <t-card class="flex flex-col justify-center items-center w-full lg:w-main-container lg:min-h-sidebar">

              <template v-if="userData">

                <MainContainerNotification
                  v-if="userData.isBanned"
                  icon-name="newContactsFilled" :section-count=1 type="error"
                >
                  <template v-slot:section1>
                    {{ trans('MESSAGE:SearchIsBlockedBecauseOfBan') }}
                  </template>
                </MainContainerNotification>

                <MainContainerNotification
                  v-else-if="!userData.isVerified"
                  icon-name="newContactsFilled" :section-count=2 type="error"
                >
                  <template v-slot:section1>
                    {{ trans('MESSAGE:SearchIsUnavailableForUnverified') }}
                  </template>
                  <template v-slot:section2>
                    <Button
                      variant="buttonRed"
                      :to="{name: 'verification-details'}"
                      :text="trans('BUTTON:VerifyIdentity')"
                    />
                  </template>
                </MainContainerNotification>

                <MainContainerNotification
                  v-else-if="!userData.isSubscriptionActive"
                  icon-name="newContactsFilled" :section-count=2 type="error"
                >
                  <template v-slot:section1>
                    {{ trans('MESSAGE:BuySubscriptionToUnlockSearch') }}
                  </template>
                  <template v-slot:section2>
                    <Button
                      variant="buttonRed"
                      :to="{name: 'subscription'}"
                      :text="trans('BUTTON:BuySubscription')"
                    />
                  </template>
                </MainContainerNotification>

                <template v-if="userData.hasFullAccess">

                  <MainContainerNotification
                    v-if="showTimeLimitMessage"
                    icon-name="newContactsFilled" :section-count=1
                  >
                    <template v-slot:section1>
                      {{ trans('MESSAGE:SearchNoMoreProfiles')}}
                    </template>
                  </MainContainerNotification>

                  <MainContainerNotification
                    v-if="showStartNewSearchMessage && !showTimeLimitMessage && !showStartNewSearchWithResetRememberedMessage"
                    icon-name="newContactsFilled" :section-count=1
                  >
                    <template v-slot:section1>
                      {{ trans('MESSAGE:SearchYouCanRunNewSearch') }}
                    </template>
                  </MainContainerNotification>

                  <MainContainerNotification
                    v-if="showStartNewSearchWithResetRememberedMessage"
                    icon-name="newContactsFilled" :section-count=2
                  >
                    <template v-slot:section1>
                      {{ trans('MESSAGE:SearchYouCanRunNewSearch') }}
                    </template>
                    <template v-slot:section2>
                      <div class="text-base lg:text-sm text-badgeSuccess leading-normal">{{ trans('MESSAGE:SearchInviteRememberedBeforeNextSearch')+' '+transChoice('MESSAGE:SearchSkippedPeopleReappearance',configData.clearRecentSearchHistoryDays) }}</div>
                    </template>
                  </MainContainerNotification>
                </template>
              </template>
		        </t-card>
	        </template>
        </div>
      </div>
      <!-- Search results END -->
    </PageContentWithSidebar>

    <Modal
      hide-footer
      variant="post"
      v-if="isShowBeforeYouStartSearchingModal"
      :show-modal="isShowBeforeYouStartSearchingModal"
      @clicked="openBeforeYouStartSearchingModal(false)"
    >
      <template #content>
        <PostShow slug="before-you-start-searching" />
      </template>
    </Modal>

    <Modal
      :header="trans('MODAL_TITLE:LocationSelection')"
      variant="map"
      hide-footer
      v-if="isShowMapModal"
      :show-modal="isShowMapModal"
      @clicked="closeMapModal"
    >
      <template v-slot:content>
        <div v-if="locationsData.length > 0" id="mapContainer"
          class="m-3 lg:m-6 border border-grayed rounded w-full lg:w-660 lg:h-660"
          :class="getHeightOfScreenWidth(1)"
        />
        <div v-else>Ładowanie danych...</div>
      </template>
    </Modal>

	  <Modal
      :header="trans('MODAL_TITLE:Attention')"
      v-if="isShowStartNewSearchModal"
      :show-modal="isShowStartNewSearchModal"
      @clicked="isShowStartNewSearchModal = false"
      @onConfirm="searchProfiles()"
      :buttonConfirmText="trans('BUTTON:StartSearch')"
	  >
		  <template v-slot:content>
			  <div class="flex flex-col gap-3 mb-3 lg:ml-3">
          <span v-if="userData.rememberedUsers.length > 0 && usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWillClearRememberedUsersAndSearchResults') }}
          </span>
          <span v-if="userData.rememberedUsers.length > 0 && !usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWillClearRememberedUsers') }}
          </span>
          <span v-if="userData.rememberedUsers.length === 0 && usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWillClearSearchResults') }}
          </span>
          <span v-if="userData.rememberedUsers.length > 0 || usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWouldYouLikeToContinue') }}
          </span>
        </div>
		  </template>
	  </Modal>

  </PageContainer>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import Loader from "@/views/Loader";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SearchSidebar from "@/views/content/filters/SearchSidebar";
import UserCard from "@/views/content/components/UserCard";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import { ref } from '@vue/composition-api'
import {goToSpecificRoute, getHeightOfScreenWidth} from "@/common/helpers/utils";
import Modal from "@/utils/modal/Modal.vue";
import ProfileGuest from "@/views/content/profile/ProfileGuest.vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Hint from "@/views/content/components/Hint";
import SidebarSectionSeparatorLine from "@/views/content/components/SidebarSectionSeparatorLine"
import PageContentWithSidebar from "@/layouts/components/pages/PageContentWithSidebar"
import Sidebar from "@/layouts/components/pages/Sidebar"
import PostShow from "@/views/content/posts/PostShow.vue";
import SearchLocation from "@/views/content/filters/SearchHistory.vue";
import LocationForm from "@/views/content/profile/components/forms/LocationForm.vue";
import Autocomplete from "@/utils/crud/components/field-types/Autocomplete.vue";
import api from "@/common/services/api";
import Countdown from "@/utils/Countdown.vue";
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";
import MainContainerNotification from "@/views/content/components/MainContainerNotification.vue"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
// import 'leaflet.markercluster';
// import 'leaflet.markercluster/dist/MarkerCluster.css';
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

export default {
  name: "SearchView",
  components: {
	  Countdown,
	  Autocomplete,
	  LocationForm,
	  SearchLocation,
    ProfileGuest,
    Modal,
    Badge,
    BadgeFrame,
    Button,
    BaseIcon,
    Hint,
    Loader,
    PageTitle,
    PageContainer,
    SearchSidebar,
    SidebarSectionSeparatorLine,
    UserCard,
    PageContentWithSidebar,
    Sidebar,
    PostShow,
    ListHeaderSection,
    MainContainerNotification
  },
  data() {
    return {
      goToSpecificRoute: goToSpecificRoute,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      showFilters: true,
      nav: {
        favourites: true,
        filters: false,
        remembered: false,
      },
	    prevRoute: null,
	    isShowProfileModal: false,
	    profileUsername: false,
      isShowBeforeYouStartSearchingModal: false,
      isShowMapModal: false,
	    userSearchLocation: null,
	    usersLoading: false,
	    searchedUserData: null,
	    usernameResult: null,
	    profilesToShowCount: null,
	    profilesToShowCountMessage: null,
	    showTimeLimitMessage: null,
	    showStartNewSearchMessage: null,
	    showStartNewSearchWithResetRememberedMessage: null,
	    showNoMoreProfilesMessage: false,
	    profileInfoLoaded: false,
	    isShowStartNewSearchModal: false,
	    profileSkipping: false,
	    profilesSearching: false,
	    gettingAnotherProfile: false,
      map:null,
    }
  },
  computed: {
    ...mapGetters('users', ['usersData', 'pagination', 'userFilters', 'favFilters', 'isInitialSearch', 'lastPage', 'backFromNewContacts', '']),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('dictionaries', ['postCategoriesData']),
	  ...mapGetters('account', ['userData']),
	  ...mapGetters('utils', ['currentSearchNav']),
	  ...mapGetters('searchHistories', ['searchHistoryData']),
	  ...mapGetters('profile', ['profileRememberedLoaded', 'searchAvailableAt']),
	  ...mapGetters('chat', ['messageSent']),
    ...mapGetters('dictionaries', ['locationsData']),

    isMobile() {
      return checkIsMobileDevice();
    },
	  containsError () {
			return this.showNoMoreProfilesMessage
					|| this.showStartNewSearchMessage
					|| this.showTimeLimitMessage
		      || this.showStartNewSearchWithResetRememberedMessage
	  }
  },
  watch: {
    langLocale() {
    },
	  profileRememberedLoaded(val) {
			if(val) {
				this.getAnotherProfile();
			}
	  },
	  messageSent(val) {
			if(val) {
				this.getAnotherProfile();
			}
	  },
	  'userData.rememberedUsers': {
			immediate: true,
		  deep: true,
			handler(val) {
				if(val && val.length === 0) {
					this.showStartNewSearchWithResetRememberedMessage = false;
					if (this.profilesToShowCount === 0) {
						this.showStartNewSearchMessage = true;
					}
				}
			}
	  },
	  searchHistoryData: {
			immediate: true,
		  deep: true,
		  handler(val) {
				if (val) {
					this.isShowBeforeYouStartSearchingModal = val.length === 0;
				}
		  }
	  }
  },
  methods: {
    ...mapActions('users', ['fetchUsersData', 'setPage']),
	  ...mapActions('searchHistories', ['fetchSearchHistory']),
	  ...mapActions('profile', ['addToRememberedRequest']),
	  ...mapActions('alert', ['error', 'success']),
    ...mapMutations('users', ['setFilters', 'unsetActiveFilter', 'setLastPage', 'setBackFromNewContacts', 'setUsersData']),
	  ...mapMutations('utils', ['setCurrentNav']),
	  ...mapMutations('profile', ['setSearchAvailableAt']),
	  ...mapMutations('account', ['cleanRememberedNames', 'cleanRememberedUsers']),
    ...mapActions('dictionaries', ['fetchLocationData']),

    showProfileModal(username) {
			this.isShowProfileModal = true;
			this.profileUsername = username;
    },

    next(val) {
			this.setLastPage(val);
			this.$router.push({name: 'search', query: {page: val}}).catch(err => err)
      this.fetchUsersData({page: val});
    },

    changeNav(section) {
			this.setCurrentNav(section);
			this.setNavigationActive(section);
    },

	  setNavigationActive(section) {
		  Object.keys(this.nav).filter(el => {
			  this.nav[el] = section === el;
		  })
	  },

    openBeforeYouStartSearchingModal(value) {
      this.isShowBeforeYouStartSearchingModal = value;
    },

    closeMapModal() {
      this.isShowMapModal = false;
      if (this.map !== null) {
        this.map.remove();
        this.map = null;
      }
    },

    openMapModal(userData) {
      this.isShowMapModal = true;

      // Upewnij się, że modal jest otwarty zanim zainicjalizujesz mapę
      this.$nextTick(() => {
        // Sprawdź, czy kontener mapy istnieje
        if (document.getElementById('mapContainer')) {
          // Poprawne przypisanie ścieżek do ikon markerów
          delete L.Icon.Default.prototype._getIconUrl;
          L.Icon.Default.mergeOptions({
            iconRetinaUrl: markerIcon2x,
            iconUrl: markerIcon,
            shadowUrl: markerShadow,
          });

          let map = this.map;
          map = L.map('mapContainer').setView([52.0651, 19.4794], 8); // srodek Polski

          // Dodanie warstwy mapy OpenStreetMap
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }).addTo(map);
          L.Popup.prototype.options.closeButton = false;

          const canvasRenderer = L.canvas();
          this.locationsData.forEach(point => {
            const marker = L.circleMarker([point.latitude, point.longitude], {
              renderer: canvasRenderer, // Użycie renderera Canvas
              radius: 10, // Rozmiar markera
              color: '#800000', // Kolor markera
            }).addTo(map)
                .bindPopup(`<div class="flex flex-col px-1.5 lg:px-3 py-1 lg:py-1.5">
                  <div class="w-full mont-font font-semibold text-sm">${point.name}</div>
                  <div class="w-full mont-font font-normal text-s">${point.region}</div>
                </div>`);
            marker.on('click', function() {
              this.locationSelectedOnMap(point);
            }.bind(this));
            marker.on('mouseover', function(e) {
              marker.openPopup();
            });
            marker.on('mouseout', function(e) {
              marker.closePopup();
            });
          });
        }
      });
    },

	  acceptLocation(value) {
			this.userSearchLocation = value.id;
	  },
    useMyLocation(userData) {
      this.locationSelected(userData.location)
    },
    locationSelected(location) {
      this.userSearchLocation = location.id;
      this.$refs.autocomplete.search = location.descRegion;
      this.$refs.autocomplete.manageClick(location);
    },
    locationSelectedOnMap(location) {
      this.locationSelected(location)
      this.closeMapModal();
    },
    showStartNewSearchAlertModal(value) {
			  this.isShowStartNewSearchModal = value;
	  },
	  searchProfiles() {
      this.$refs.autocomplete.search = '';
			this.showStartNewSearchAlertModal(false);
			this.profilesSearching = true;
			let data = {
				location_id: this.userSearchLocation,
				user_id: this.userData.id,
			};
		  api.post(`/search-profiles`, data)
				  .then(
						  success => {
								this.fetchSearchHistory()
							  this.usernameResult = success.data.username;
								this.getSearchProfilesInfo();
                this.cleanRememberedNames();
                this.cleanRememberedUsers();
						  },
						  error => {
							  this.error(error);
						  }
				  )
				  .finally(() => {
					  this.profilesSearching = false;
					  }
				  );
	  },


	  getSearchUser() {
			this.usersLoading = true;

		  api.get(`/users`)
				  .then(
						  success => {
								if (success.data.username) {
									this.usernameResult = success.data.username;
									this.getSearchProfilesInfo();
								} else {
									this.usernameResult = null;
								}
						  },
						  error => {
						  }
				  ).finally(() => {
			  this.usersLoading = false;
		  });
	  },

	  getSearchProfilesInfo() {
		  this.profileInfoLoaded = false;

		  api.get(`/search-profiles-info`)
				  .then(
						  success => {
							  this.profileInfoLoaded = true;
								if(success.data.searchAvailableAt !== null) {
									this.setSearchAvailableAt(new Date(success.data.searchAvailableAt));
								} else {
									this.setSearchAvailableAt(null);
								}
							  this.profilesToShowCount = success.data.profilesToShowCount;
							  this.profilesToShowCountMessage = success.data.profilesToShowCountMessage;
							  this.showTimeLimitMessage = success.data.showTimeLimitMessage;
							  this.showStartNewSearchMessage = success.data.showStartNewSearchMessage;
							  this.showStartNewSearchWithResetRememberedMessage = success.data.showStartNewSearchWithResetRememberedMessage;
							  this.showNoMoreProfilesMessage = success.data.showNoMoreProfilesMessage;
						  },
						  error => {
						  }
				  ).finally(() => {
        this.profileInfoLoaded = true;
		  });
	  },

	  skipProfile() {
			this.profileSkipping = true;
		  api.post(`/skip-profile`)
				  .then(
						  success => {
							  this.getAnotherProfile();
						  },
						  error => {
						  }
				  ).finally(() => {
			  this.profileSkipping = false;
		  });
	  },

	  getAnotherProfile() {
			this.gettingAnotherProfile = true;
		  api.post(`/get-another-profile`)
				  .then(
						  success => {
							  this.usernameResult = success.data.username;

								this.getSearchProfilesInfo();
						  },
						  error => {
						  }
				  ).finally(() => {
			  this.gettingAnotherProfile = false;
		  });
	  },

	  addToRemembered(event) {
		  this.addToRememberedRequest({rememberedUsername: event.username});
			this.getAnotherProfile();
	  },

	  clearTimer() {
			this.getSearchProfilesInfo();
      this.userSearchLocation=null;
	  },

    async loadLocationData() {
      try {
        // Czekaj na zakończenie pobierania danych
        await this.fetchLocationData();
        console.log('Pobrane dane lokalizacji:');
      } catch (error) {
        console.error('Błąd podczas pobierania danych lokalizacji:', error);
      }
    }

  },

  setup() {
    const currentPage = ref(1)

    return { currentPage }
  },

  mounted() {
    if(this.currentSearchNav) {
			this.setNavigationActive(this.currentSearchNav);
		}
	  this.getSearchProfilesInfo();
		this.getSearchUser();
    this.loadLocationData();
  },
	beforeMount() {
	},
	beforeDestroy() {
		this.setUsersData([]);
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
  provide() {
    return {
      searchAllowed: this.searchAllowed
    };
  }
}
</script>
<style lang="scss">
.leaflet-popup-content-wrapper {
  padding: 0px;
  margin:0px;
  border-radius: 0.25rem;
}
.leaflet-popup-content{
  padding:0px;
  margin: 0px;
}

</style>